import React, { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { useParams } from 'react-router-dom';

import { Panel } from '@soc/assets/src';
import { Tab, Tabs } from 'combinezone/core';

import { fetchIncidents } from 'store/reducers/cmdbAssetPage/actions';

import { IncidentsTab } from './IncidentsTab';
import { ContentWrapper, TabsWrapper, Layout } from './components';

const CmdbAssetPage: FC = ({ locale, user }) => {
  const dispatch = useDispatch();
  const { assetId } = useParams<{ assetId: string }>();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const tabs = useMemo(
    () => [
      {
        key: 'incidents',
        name: I18n.t('titles.incidentList'),
      },
    ],
    [locale],
  );

  const onChangeTab = useCallback(
    (tabIndex: number) => {
      if (activeTabIndex !== tabIndex) {
        setActiveTabIndex(tabIndex);
      }
    },
    [activeTabIndex],
  );

  const contentHeader = useMemo(
    () => (
      <TabsWrapper>
        <Tabs
          onChange={onChangeTab}
          selectedTabIndex={activeTabIndex}
          type="horizontal"
        >
          {tabs.map(({ key, name }) => (
            <Tab key={key} testId={`cmdb-assets-page-tab-${key}`}>
              {name}
            </Tab>
          ))}
        </Tabs>
      </TabsWrapper>
    ),
    [activeTabIndex, onChangeTab],
  );

  useEffect(() => {
    dispatch(fetchIncidents(assetId));
  }, []);

  return (
    <Layout>
      <Panel user={user} locale={locale} accessEdit={false} assetId={assetId} />
      <ContentWrapper>
        {contentHeader}
        <IncidentsTab />
      </ContentWrapper>
    </Layout>
  );
};

export default CmdbAssetPage;
