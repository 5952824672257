import { jwtDecode } from 'jwt-decode';

import * as types from './actionTypes';

const initialState = () => ({
  refresh: localStorage.getItem('refreshToken'),
  access: localStorage.getItem('accessToken'),
  tokenType: localStorage.getItem('tokenType'),
  mfaToken: localStorage.getItem('mfaToken'),
  timeToGetNextMfaToken: 60,
  isAuthenticated: null,
  isLoading: true,
  isTokenFetching: false,
  user: null,
  errors: {},
  isMounted: false,
  hasMfa: !!localStorage.getItem('mfaToken'),
  oidcUserManager: null,
});

export default function auth(state = initialState(), action = {}) {
  switch (action.type) {
    case types.USER_LOADING:
      return { ...state, isLoading: true };

    case types.USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      };

    case types.LOGIN_REQUEST:
    case types.TOKEN_REQUEST:
    case types.CHECK_SMS_REQUEST:
    case types.SEND_SMS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isTokenFetching: true,
      };

    case types.LOGIN_SUCCESSFUL:
    case types.TOKEN_RECEIVED:
    case types.CHECK_SMS_SUCCESS:
      if (!action.payload.access) {
        localStorage.setItem('mfaToken', action.payload.mfa_token);

        return {
          ...state,
          isLoading: false,
          isTokenFetching: false,
          errors: {},
          timeToGetNextMfaToken: action.payload.generate_challenge_timeout,
          mfaToken: action.payload.mfa_token,
          hasMfa: true,
        };
      }
      localStorage.setItem('accessToken', action.payload.access);
      localStorage.setItem('refreshToken', action.payload.refresh);
      localStorage.setItem('tokenType', 'SOCPortal');

      localStorage.removeItem('mfaToken');

      return {
        ...state,
        ...action.payload,
        user: jwtDecode(action.payload.access).user,
        isAuthenticated: true,
        isLoading: false,
        isTokenFetching: false,
        errors: {},
        tokenType: 'SOCPortal',
        hasMfa: false,
        mfaToken: null,
      };

    case types.LOGIN_FAILED:
    case types.TOKEN_FAILURE:
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('tokenType');

      return {
        ...state,
        errors: action.payload.response,
        refresh: null,
        access: null,
        hasMfa: false,
        mfaToken: null,
        tokenType: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
        isTokenFetching: false,
      };

    case types.USER_FAILURE:
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('tokenType');
      localStorage.removeItem('mfaToken');
      return {
        ...state,
        errors: action.payload.response,
        refresh: null,
        access: null,
        hasMfa: false,
        mfaToken: null,
        tokenType: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };

    case types.LOGOUT:
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('tokenType');

      return {
        ...initialState(),
        isLoading: false,
        isAuthenticated: false,
      };

    case types.SSO_TOKEN_RECEIVED:
      localStorage.setItem('accessToken', action.payload.access);
      localStorage.setItem('refreshToken', action.payload.refresh);
      localStorage.setItem('tokenType', 'SSO');
      return {
        ...state,
        ...action.payload,
        tokenType: 'SSO',
      };

    case types.MOUNTED:
      return { ...state, isMounted: true };

    case types.SEND_SMS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isTokenFetching: false,
      };
    }

    case types.RESET_MFA:
      localStorage.removeItem('mfaToken');

      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        hasMfa: false,
        mfaToken: null,
        errors: {},
      };

    case types.CHECK_SMS_FAILED:
    case types.SEND_SMS_FAILED:
      let { hasMfa } = state;
      let { mfaToken } = state;

      if (action.payload.response.mfa_token) {
        localStorage.removeItem('mfaToken');
        hasMfa = false;
        mfaToken = null;
      }

      return {
        ...state,
        hasMfa,
        mfaToken,
        isLoading: false,
        isTokenFetching: false,
        errors: action.payload.response,
      };

    case types.OIDC_USER_LOADING:
      return { ...state, isLoading: true };

    case types.OIDC_USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
        refresh: localStorage.getItem('refreshToken'),
        access: localStorage.getItem('accessToken'),
        tokenType: localStorage.getItem('tokenType'),
      };

    case types.CHANGE_OIDC_USER_MANAGER:
      return {
        ...state,
        oidcUserManager: action.payload,
      };

    default:
      return state;
  }
}

export function isAccessTokenExpired(state) {
  if (state.access && state.access !== 'undefined') {
    const decodedToken = jwtDecode(state.access);

    if (decodedToken && decodedToken.exp) {
      return 1000 * decodedToken.exp - new Date().getTime() < 5000;
    }
  }

  return true;
}

export function isRefreshTokenExpired(state) {
  if (state.refresh && state.refresh !== 'undefined') {
    const decodedToken = jwtDecode(state.refresh);

    if (decodedToken && decodedToken.exp) {
      return 1000 * decodedToken.exp - new Date().getTime() < 5000;
    }
  }

  return true;
}

export function refreshToken(state) {
  if (state.refresh) {
    return state.refresh;
  }
}

export function isAuthenticated(state) {
  return !!state.isAuthenticated;
}

export function accessToken(state) {
  if (state.access) {
    return state.access;
  }
}

export function authHeaderType(state) {
  switch (state.tokenType) {
    case 'SSO':
      return 'SSOBearer';
    case 'OIDC':
      return 'OIDC';
    default:
      return 'Bearer';
  }
}

export function isTokenFetching(state) {
  return state.isTokenFetching;
}

export function getUser(state) {
  return state.user;
}

export function getIsMounted(state) {
  return state.isMounted;
}

export const getMfaToken = (state) => state.mfaToken;
export const getTimeToGetNextMfaToken = (state) => state.timeToGetNextMfaToken;

export function oidcUserManager(state) {
  return state.oidcUserManager;
}
