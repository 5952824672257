import React, { useCallback, useEffect } from 'react';
import { Route, Switch, useHistory, withRouter } from 'react-router-dom';

import { ConfigProvider } from 'antd';
import enEn from 'antd/lib/locale-provider/en_US';
import ruRu from 'antd/lib/locale-provider/ru_RU';
import { ToastProvider, OverlaySpinner } from 'combinezone/core';
import { ThemeProvider } from 'combinezone/theme';
import { LanguageProvider } from 'combinezone/utils';

import LoginContainer from '../../containers/LoginContainer';
import messageService from '../../services/message';

import './App.scss';
import { useInitTheme } from '../../utils/brending';
import Main from '../Main';

export default function AppComponent({
  auth: { isAuthenticated },
  handleSSOToken,
  loadUser,
  locale,
  setLocale,
  setMounted,
}) {
  const [socTheme, combinezoneTheme] = useInitTheme();

  const selectLanguage = useCallback((lng) => {
    setLocale(lng);
  }, []);

  const history = useHistory();

  useEffect(() => {
    history.listen((location) => {
      messageService.sendRoute({
        location: location.pathname,
      });
    });
    const timerId = setTimeout(() => {
      loadUser();
    }, 1000);

    messageService.sendMounted();

    messageService.onReceive(({ data, type }) => {
      if (type === messageService.CONTEXT) {
        clearTimeout(timerId);
        const userAuth = data.currentUser.auth;
        handleSSOToken(userAuth.access_token, userAuth.access_token);
        setMounted();

        const cyberspaceLang = data.lang;
        setTimeout(() => {
          if (cyberspaceLang === 'ru-ru') {
            selectLanguage('ru');
          } else if (cyberspaceLang === 'en-us') {
            selectLanguage('en');
          }
        }, 1000);
      }
    });
  }, []);
  return (
    <ThemeProvider theme={combinezoneTheme}>
      <ToastProvider>
        <ConfigProvider locale={locale === 'en' ? enEn : ruRu}>
          <LanguageProvider language={locale === 'en' ? 'en' : 'ru'}>
            {isAuthenticated === null ? (
              <OverlaySpinner isFullscreen />
            ) : (
              <Switch>
                {!isAuthenticated ? (
                  <Route path="/" component={LoginContainer} />
                ) : (
                  <Route path="/" component={Main} />
                )}
              </Switch>
            )}
          </LanguageProvider>
        </ConfigProvider>
      </ToastProvider>
    </ThemeProvider>
  );
}

export const App = withRouter(AppComponent);
