import { isAccessTokenExpired } from '@soc/kit/api';
import { getRenewTokensResult } from '@soc/kit/api/hooks/beforeRequest/helpers';
import { LOAD_TRANSLATIONS, SET_LOCALE } from 'react-redux-i18n/build/actions';
import { AnyAction, Dispatch } from 'redux';
import { RSAA } from 'redux-api-middleware';
import { PERSIST } from 'redux-persist';

import * as types from '../reducers/auth/actionTypes';
import {
  CHECK_SMS_REQUEST,
  SEND_SMS_REQUEST,
} from '../reducers/auth/actionTypes';
import { logout } from '../reducers/auth/actions';
import { UNSET_DASHBOARD_UPDATE_TIMER } from '../reducers/dashboard/actionTypes';

const isIgnoreRenewToken = (action: AnyAction): boolean => {
  return (
    !!action.isIgnoreTokenRefresh ||
    !!action[RSAA]?.types?.includes(types.LOGIN_REQUEST) ||
    !!action[RSAA]?.types?.includes(CHECK_SMS_REQUEST) ||
    !!action[RSAA]?.types?.includes(SEND_SMS_REQUEST) ||
    action.type === types.LOGOUT ||
    action.type === LOAD_TRANSLATIONS ||
    action.type === SET_LOCALE ||
    action.type === 'RESET' ||
    action.type === UNSET_DASHBOARD_UPDATE_TIMER ||
    action.type === PERSIST
  );
};

export const renewToken =
  ({ dispatch }: { dispatch: Dispatch }) =>
  (next: (action: AnyAction) => unknown) =>
  async (action: AnyAction) => {
    if (isIgnoreRenewToken(action)) {
      return next(action);
    }

    if (isAccessTokenExpired()) {
      const tokenAccess = await getRenewTokensResult();

      if (!tokenAccess) {
        return dispatch(logout({ isForcibly: true }));
      }
    }

    return next(action);
  };
