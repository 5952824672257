import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import styled from 'styled-components';

import { Dropdown, Flex, IconButton, Select } from 'combinezone/core';
import { SortDesc, SortAsc, Delete } from 'combinezone/icons/index';
import { useIsClosable } from 'combinezone/utils';

import { SortDirection } from 'shared/components/SortControl/enums';
import {
  getSortConditions,
  getSortDirections,
} from 'shared/components/SortControl/helpers';
import {
  FiltersRootState,
  SortControlProps,
} from 'shared/components/SortControl/types';
import { setSort } from 'store/reducers/filters/actions';

const DropdownContainer = styled(Dropdown)`
  margin-top: 10px;
  padding: 8px !important;
`;

const SelectContainer = styled(Select)`
  width: 210px;
`;

const SortControl: FC<SortControlProps> = ({
  data,
  entity,
  testId,
  updateFunction,
}) => {
  const testIdSort = `${testId}-sort`;
  const dispatch = useDispatch();
  const { close, isOpen, open } = useIsClosable();
  const { ordering } = useSelector(
    (state: FiltersRootState) => state.filters[entity],
  );

  const conditionOptions = getSortConditions(data, testIdSort, entity);

  const [condition, setCondition] = useState<string>();
  const [direction, setDirection] = useState<SortDirection>();

  const onChangeConditionHandler = useCallback(
    (conditionValue?: string): void => {
      if (conditionValue) {
        setCondition(conditionValue);
        setDirection(undefined);
      }
    },
    [],
  );

  const onChangeDirectionHandler = useCallback(
    (directionValue?: string): void => {
      if (directionValue) {
        setDirection(directionValue as SortDirection);
      }
      close();
    },
    [close],
  );

  const onResetHandler = useCallback((): void => {
    setCondition(undefined);
    setDirection(undefined);
    dispatch(setSort(entity, {}, updateFunction));
    close();
  }, [close]);

  useEffect(() => {
    if (!Object.keys(ordering).length) {
      return;
    }

    const [orderingCondition, orderingDirection] =
      Object.entries(ordering).flat();

    setCondition(orderingCondition);
    setDirection(orderingDirection as SortDirection);
  }, [ordering]);

  useEffect(() => {
    const newOrdering = direction ? { [condition as string]: direction } : {};
    const isDispatch = condition && direction;

    if (isDispatch) {
      dispatch(setSort(entity, newOrdering, updateFunction));
    }
  }, [direction, condition]);

  useEffect(() => {
    if (!isOpen && condition && !direction) {
      onResetHandler();
    }
  }, [isOpen, condition, direction, onResetHandler]);

  return (
    <DropdownContainer
      closeOnBlur={false}
      isOpen={isOpen}
      position="bottom-right"
      content={
        <Flex gap="10px">
          <SelectContainer
            testId={`${testIdSort}-condition`}
            value={condition}
            onChange={onChangeConditionHandler}
            options={conditionOptions}
            placeholder={I18n.t(`sort.condition.placeholder`)}
          />
          <SelectContainer
            testId={`${testIdSort}-value`}
            value={direction}
            onChange={onChangeDirectionHandler}
            options={getSortDirections(testId, entity, condition)}
            placeholder={I18n.t(`sort.direction.placeholder`)}
            disabled={!condition}
          />
          <IconButton
            testId={`${testIdSort}-button-reset`}
            icon={Delete}
            tooltip={{
              content: I18n.t(`sort.buttons.reset.tooltip`),
              position: 'top-right',
            }}
            onClick={onResetHandler}
          />
        </Flex>
      }
    >
      <IconButton
        testId={`${testIdSort}-button`}
        icon={direction === SortDirection.Asc ? SortAsc : SortDesc}
        isPressed={isOpen}
        tooltip={null}
        variant="transparentWithBorder"
        accent={condition && direction ? 'active' : 'default'}
        onClick={isOpen ? close : open}
      />
    </DropdownContainer>
  );
};

export default SortControl;

SortControl.displayName = 'SortControl';
