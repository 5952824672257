export const WITH_SENTRY =
  process.env.NODE_ENV !== 'development' ||
  process.env.FORCE_ENABLE_SENTRY === 'true';
export const SENTRY_ENVIRONMENT =
  process.env.NODE_ENV === 'development'
    ? 'development (local)'
    : process.env.REACT_APP_SENTRY_ENVIRONMENT;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

export const HAS_REQUEST_CATEGORIES =
  process.env.REACT_APP_HAS_REQUEST_CATEGORIES === 'true';

export const UPDATE_FIELD = 'updated';
export const CREATE_FIELD = 'created';
export const PRIORITY_FIELD = 'priority';
export const STATUS_FIELD = 'status';
export const CATEGORY_FIELD = 'category';
export const SECONDARY_CATEGORY_FIELD = 'secondary_category';
export const USER_FIELD = 'user';
export const CUSTOMER_ASSIGNEE = 'customer_assignee';
export const TAGS = 'tags';
export const CUSTOMER_ASSIGNEE_LAST_TIME = 'customer_assignee_last_time';
export const CUSTOMER_ASSIGNEE_FIELD = 'customer_assignee__username';
export const KEY_FIELD = 'key';
export const ID_FIELD = 'id';
export const SYSTEM_FIELD = 'system';
export const TYPE_FIELD = 'issue_type';
export const SUBMITER_FIELD = 'reporter_displayName';
export const CUSTOMER_FIELD = 'customer';
export const SERVICE_FIELD = 'service';
export const ATTACHMENTS_FIELD = 'attachments';
export const SLA_FIELD = 'sla';
export const SLA_BREACHED_FIELD = 'sla_breached';
export const TITLE_FIELD = 'summary';
export const DESCRIPTION_FIELD = 'rendered_description';
export const COMMENTS_FIELD = 'comments';
export const MITRE_FIELD = 'mitre_cov';
export const RULES_FIELD = 'detection_rules';
export const RATING_STARS = 'customer_star_rating';
export const RATING_COMMENT = 'customer_star_rating_comment';
export const RESOLUTION_DATE_FIELD = 'resolutiondate';
export const RESOLUTION_DETAILED_FIELD = 'resolution_detailed';
export const RESOLUTION_NAME_FIELD = 'resolution_name';
export const STATUS_DESCRIPTION_FIELD = 'status_description';
export const AFFECTED_SYSTEMS = 'affected_systems';
export const ACTIVITY_DETECTED = 'parsed_activity_detected';
export const GOSSOPKA_SEND_TIME = 'gossopka_send_time';
export const GOSSOPKA_ID = 'gossopka_id';
export const RULES = 'rules';
export const MITRE_TECHNIQUES = 'mitre_techniques';
export const MITRE_TACTICS = 'mitre_tactics';
export const RECOMMENDATIONS_FIELD = 'recommendations';
export const SHOW_GOSSOPKA_BUTTON = 'show_gossopka_button';
export const OBSERVED_IOCS = 'observed_iocs';
export const PARSED_ACTIVITY_DETECTED = 'parsed_activity_detected';

// log sources fields
export const LOGSOURCES_EPS = 'average_eps';
export const LOGSOURCES_LAST_EVENT = 'last_event_time';
export const LOGSOURCES_CREATED = 'created';
export const LOGSOURCES_TAGS = 'tags';
export const LOGSOURCES_COLLECTOR = 'collector';
export const LOGSOURCES_TYPE = 'log_source_type';
export const LOGSOURCES_ARCHIVED_DATE = 'archived_date';
export const LOGSOURCES_EPS_METRICS = 'eps_metrics';
export const LOGSOURCES_LAST_EVENT_TIME = 'last_event_time';
export const LOGSOURCES_AVG_LAST_HOUR = 'avg_last_hour';

// rules fields
export const RULES_CONFIDENCE = 'confidence';
export const RULES_CATEGORY = 'category';
export const RULES_SEVERITY = 'severity';
export const RULES_TECHNIQUE = 'mitre_technique';
export const RULES_TACTIC = 'mitre_tactic';
export const RULES_CREATED = 'created';
export const RULES_ID = 'rule_id';
export const RULES_PLATFORM = 'mitre_platform';

// instructions fields
export const INSTRUCTIONS_ID = 'id';
export const INSTRUCTIONS_NAME = 'name';
export const INSTRUCTIONS_DESCRIPTION = 'description';
export const INSTRUCTIONS_SENSORS = 'sensors';
export const INSTRUCTIONS_TAGS = 'tags';
export const INSTRUCTIONS_UPDATED = 'updated';
export const INSTRUCTIONS_CREATED = 'created';
export const INSTRUCTIONS_ATTACHMENTS = 'attachments';
export const INSTRUCTIONS_DOCUMENT = 'document';
export const INSTRUCTIONS_LANGUAGE = 'language';

// shedule fields
export const SHEDULE_ID = 'id';
export const SHEDULE_NAME = 'name';
export const SHEDULE_EMAILS = 'emails';
export const SHEDULE_SYSTEMS = 'systems';
export const SHEDULE_CRONTAB = 'crontab';
export const SHEDULE_START_TIME = 'start_time';

// entities
export const INCIDENTS_ENTITY = `incidents`;
export const SOURCES_ENTITY = `logSources`;
export const PROTECTED_SYSTEMS_ENTITY = `protectedSystems`;
export const INSTRUCTIONS_ENTITY = `instructions`;
export const RESPONSE_INSTRUCTIONS_ENTITY = `responseInstructions`;
export const ARTICLES_ENTITY = `articles`;
export const RULES_ENTITY = `rules`;

export const API_URL = process.env.REACT_APP_API_URL;
export const INCIDENTS_ENDPOINT = `${API_URL}/soc/incs/`;
export const ALERTS_ENDPOINT = `${API_URL}/soc/alerts/`;
export const RESPONSE_TEAM_ENDPOINT = `${API_URL}/soc/response_teams/`;
export const TAGS_ENDPOINT = `${API_URL}/soc/tags/`;
export const USERS_ENDPOINT = `${API_URL}/soc/users/`;
export const LOGSOURCES_ENDPOINT = `${API_URL}/soc/logsources/`;
export const LOGSOURCES_COUNTERS_ENDPOINT = `${API_URL}/soc/logsources/counters/`;
export const INCIDENTS_CSV_ENDPOINT = `${INCIDENTS_ENDPOINT}get_csv/`;
export const INCIDENTS_CSV_ASYNC_ENDPOINT = `${INCIDENTS_ENDPOINT}get_async_csv/`;
export const LOGSOURCES_CSV_ENDPOINT = `${LOGSOURCES_ENDPOINT}get_csv/`;
export const LOGSOURCES_CSV_ASYNC_ENDPOINT = `${LOGSOURCES_ENDPOINT}get_async_csv/`;
export const DASHBOARD_ENDPOINT = `${INCIDENTS_ENDPOINT}analytics/`;
export const DASHBOARD_EPS_ENDPOINT = `${API_URL}/soc/dashboards/eps/`;
export const MITRE_MATRIX_ENDPOINT = `${API_URL}/soc/mitre_matrix/`;
export const SYSTEMS_ENDPOINT = `${API_URL}/soc/systems/`;
export const FILTERS_ENDPOINT = `${API_URL}/soc/filters/`;
export const CREATE_REPORT_ENDPOINT = `${API_URL}/soc/create_report/`;
export const REPORT_TASKS_ENDPOINT = `${API_URL}/soc/report_tasks/`;
export const REPORTS_ENDPOINT = `${API_URL}/soc/files/reports/`;
export const INSTRUCTIONS_ENDPOINT = `${API_URL}/soc/instruction/`;
export const ARTICLES_ENDPOINT = `${API_URL}/soc/article/`;
export const NEW_VERSION_ARTICLE_ENDPOINT = `${ARTICLES_ENDPOINT}last_release_note/`;
export const RESPONSE_INSTRUCTIONS_ENDPOINT = `${API_URL}/soc/response_instructions/`;
export const INSTRUCTION_ENDPOINT = `${API_URL}/soc/instruction/`;
export const ARTICLE_ENDPOINT = `${API_URL}/soc/article/`;
export const RULE_ENDPOINT = `${API_URL}/soc/rules/`;
export const API_URL_SOC = `${API_URL}/soc`;

export const DOCUMENTATION_ENDPOINTS = {
  article: ARTICLES_ENDPOINT,
  instruction: INSTRUCTIONS_ENDPOINT,
  responseInstruction: RESPONSE_INSTRUCTIONS_ENDPOINT,
};

const transformBrand = ({ data }) => {
  return process.env.REACT_APP_BRAND_DOMAIN && process.env.REACT_APP_BRAND_NAME
    ? JSON.parse(JSON.stringify(data), (key, value) => {
        if (typeof value === 'string') {
          return value
            .replace(/soc.bi.zone/gi, process.env.REACT_APP_BRAND_DOMAIN)
            .replace(
              /(bi.zone|bizone|bi zone)/gi,
              process.env.REACT_APP_BRAND_NAME,
            );
        }
        return value;
      })
    : data;
};

export async function fetchAllPages(...args) {
  let pagesData = [];
  let nextPage = args[0];

  do {
    const response = await fetch(...args);

    if (!response.ok) {
      return response; // Let it handle to redux-api-middleware
    }

    let data = await response.json();

    data = transformBrand({ data });

    pagesData = pagesData.concat(data.results);
    nextPage = data.next;

    args[0] = nextPage;
  } while (nextPage);

  return new Response(JSON.stringify(pagesData), {
    status: 200,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function encodeElasticFilter(filter) {
  const encodeInner = (obj, joiner = ',') => {
    let result = [];

    Object.keys(obj).forEach((key) => {
      if (key === 'ordering') {
        for (const [sortKey, sortValue] of Object.entries(obj[key])) {
          result.push(
            `ordering=${sortValue === 'ASC' ? sortKey : `-${sortKey}`}`,
          );
        }
      } else if (key === GOSSOPKA_ID) {
        if (obj[key].in.length === 1) {
          result.push(`${key}__isnull=${obj[key].in[0]}`);
        }
      } else if (Array.isArray(obj[key])) {
        result.push(
          `${key}=${obj[key]
            .map((item) => encodeURIComponent(item))
            .join(joiner)}`,
        );
      } else if (typeof obj[key] === 'object') {
        const inner = encodeInner(obj[key], joiner);
        result = result.concat(inner.map((x) => `${key}__${x}`));
      } else {
        result.push(`${key}=${encodeURIComponent(obj[key])}`);
      }
    });

    return result;
  };

  return `?${encodeInner(filter).join('&')}`;
}

function encodeDateFilters(dashboardFilter, dateFields) {
  return dateFields.reduce((filter, field) => {
    if (dashboardFilter[field]) {
      filter[field] = {
        gte: dashboardFilter[field][0].startOf('day').format('x'),
        lte: dashboardFilter[field][1].endOf('day').format('x'),
      };
    }
    return filter;
  }, {});
}

function encodeDateFiltersISO(dashboardFilter, dateFields) {
  return dateFields.reduce((filter, field) => {
    if (dashboardFilter[field]) {
      filter[field] = {
        gte: dashboardFilter[field][0].startOf('day').toISOString(),
        lte: dashboardFilter[field][1].endOf('day').toISOString(),
      };
    }
    return filter;
  }, {});
}

function encodeSelectFilters(dashboardFilter, selectFilters) {
  return selectFilters.reduce((filter, field) => {
    if (dashboardFilter[field]) {
      filter[field] = dashboardFilter[field];
    }
    return filter;
  }, {});
}

export function encodeIncidentsFilter(
  dashboardFilter,
  startTimestamp,
  endTimestamp,
  ordering,
  search = '',
) {
  const filter = {
    ...Object.keys(dashboardFilter)
      .filter((key) => key !== CREATE_FIELD)
      .reduce((acc, key) => {
        if (key === UPDATE_FIELD) {
          acc[key] = {
            gt: dashboardFilter[key],
          };
        } else {
          acc[key] = {
            in: dashboardFilter[key],
          };
        }

        return acc;
      }, {}),
    ...encodeDateFilters(dashboardFilter, [
      CREATE_FIELD,
      RESOLUTION_DATE_FIELD,
      SLA_FIELD,
    ]),
  };

  if (!filter[CREATE_FIELD]) {
    const timeFilter = {};
    if (startTimestamp) {
      timeFilter.gte = startTimestamp;
    }
    if (endTimestamp) {
      timeFilter.lte = endTimestamp;
    }
    filter[CREATE_FIELD] = timeFilter;
  }

  if (ordering) {
    filter.ordering = ordering;
  }

  if (search) {
    filter.search = search;
  }

  return encodeElasticFilter(filter);
}

export function encodeProtectedSystemsFilter(filters, sort) {
  const params = [];
  if (Object.keys(sort).length !== 0) {
    params.push(
      `ordering=${sort.type === 'ASC' ? sort.field : `-${sort.field}`}`,
    );
  }
  Object.keys(filters).forEach((filter) => {
    const value = filters[filter];
    const postfix = typeof value === 'string' ? 'icontains' : 'in';
    params.push(`${filter}__${postfix}=${value}`);
  });

  return params.join('&');
}

export function encodeLogSourcesFilter(filters, ordering, search = '') {
  const filter = {
    ...Object.keys(filters).reduce((acc, key) => {
      acc[key] = {
        in: filters[key],
      };

      return acc;
    }, {}),
    ...encodeDateFilters(filters, [LOGSOURCES_CREATED, LOGSOURCES_LAST_EVENT]),
  };

  if (ordering) {
    filter.ordering = ordering;
  }

  if (search) {
    filter.search = search;
  }

  return encodeElasticFilter(filter);
}

export function encodeInstructionsFilter(
  filters,
  ordering,
  search = '',
  locale = 'ru',
) {
  const filter = {
    ...Object.keys(filters).reduce((acc, key) => {
      acc[key] = {
        in: filters[key],
      };

      return acc;
    }, {}),
    ...encodeDateFilters(filters, [INSTRUCTIONS_UPDATED, INSTRUCTIONS_CREATED]),
  };

  if (ordering) {
    const [type, value] = Object.entries(ordering)[0];

    filter.ordering =
      type === INSTRUCTIONS_NAME ? { [INSTRUCTIONS_NAME]: value } : ordering;
  }

  if (search) {
    filter.search = search;
  }

  filter.language = locale === 'ru' ? 'ru' : 'en';

  return encodeElasticFilter(filter);
}

export function encodeRulesFilter(
  filters,
  ordering,
  search = '',
  locale = 'ru',
) {
  const filter = {
    ...Object.keys(filters).reduce((acc, key) => {
      acc[key] = {
        in: filters[key],
      };

      return acc;
    }, {}),
    ...encodeDateFiltersISO(filters, [RULES_CREATED]),
    ...encodeSelectFilters(filters, ['activation_mode', 'is_requested']),
  };

  if (ordering) {
    filter.ordering = ordering;
  }

  if (search) {
    filter.search = search;
  }

  filter.language = locale === 'ru' ? 'ru' : 'en';

  return encodeElasticFilter(filter);
}

export function encodeReportsFilter(filters, sort) {
  const bInMb = 1024 * 1024;
  const params = Object.keys(filters).map((name) => {
    if (name === 'size') {
      return `${name}__gte=${filters[name].from * bInMb}&${name}__lte=${
        filters[name].to * bInMb
      }`;
    }
    return `${name}=${filters[name]}`;
  });
  if (Object.keys(sort).length !== 0) {
    params.push(
      `ordering=${sort.type === 'ASC' ? sort.field : `-${sort.field}`}`,
    );
  } else {
    params.push(`ordering=-${CREATE_FIELD}`);
  }

  return params.join('&');
}

export function encodeIncsChangesFilter(
  dashboardFilter,
  startTimestamp,
  endTimestamp,
  lastReceivedIncTimestamp,
) {
  const filter = {
    ...dashboardFilter,
    [UPDATE_FIELD]: lastReceivedIncTimestamp,
  };
  return encodeIncidentsFilter(filter, startTimestamp, endTimestamp);
}
