import { getApiErrorMessage } from '@common/soc-react-kit';
import { notification } from 'antd';

export default function showErrorMessage() {
  return (next) => (action) => {
    const { error = false, meta = {}, payload } = action;

    if (error && meta.showNotification !== false) {
      const { message, response, status } = payload;

      const description = getApiErrorMessage(response, status);

      const isAbortedAction =
        message === 'The user aborted a request.' ||
        message ===
          "Failed to execute 'fetch' on 'Window': The user aborted a request.";

      if (isAbortedAction) {
        action.type += '_ABORTED_REQUEST';
        return next(action);
      }

      if (response === undefined || description) {
        notification.error({
          message: meta.customMessage || message,
          description,
        });
      }
    }

    return next(action);
  };
}
