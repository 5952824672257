import EventEmitter from 'events';

const eventEmitter = new EventEmitter();

class Message {
  constructor() {
    this.name = 'Message';

    this.url = window.location.origin;

    this.child = null;
  }

  MESSAGE = '@@ms/MESSAGE';

  ROUTE = '@@ms/ROUTE';

  CONTEXT = '@@ms/CONTEXT';

  MOUNTED = '@@ms/MOUNTED';

  register(name) {
    this.child = window.frames[name];
  }

  getContext() {
    return this.context;
  }

  sendContext(context) {
    this.send(
      {
        type: this.CONTEXT,
        message: context,
      },
      this.child,
    );
  }

  sendMounted() {
    this.send({
      type: this.MOUNTED,
    });
  }

  sendRoute(message) {
    this.send({
      type: this.ROUTE,
      message,
    });
  }

  send(
    { message = {}, params = {}, type = this.MESSAGE },
    target = window.parent,
  ) {
    target.postMessage(
      {
        type,
        message,
        params,
      },
      '*',
    );
  }

  handler(event) {
    const isValidMessage =
      event.data && event.data.type && event.data.type.startsWith('@@ms/');

    if (isValidMessage) {
      eventEmitter.emit('message', {
        type: event.data.type,
        data: event.data.message,
      });
    }
  }

  onReceive(cb) {
    eventEmitter.on('message', (args) => cb(args));
  }
}

const message = new Message();
export default message;
