import { useCallback, useEffect, useState } from 'react';

import { abortApiFetch } from '@soc/kit/api';

import { fetchConfigProviders } from '../actions/fetchConfigProviders';
import { fetchOidcProviders } from '../actions/fetchOidcProviders';
import { OidcProvidersFetchAbortControllerKey } from '../constants';
import { ConfigProviderType, OidsProviderType, ProviderType } from '../types';

import { UseOidcProviders } from './types';

export const useOidcProviders: UseOidcProviders = () => {
  const [providers, setProviders] = useState<ProviderType[] | null>(null);
  const [isFetchingProviders, setIsFetchingProviders] = useState(false);

  const getConfigProviders = async (oidcProvidersList: Array<OidsProviderType>): Promise<any> => {
    const providersList = oidcProvidersList.map(async ({ client_id, configuration_url, name }) => {
      let configProviders: ConfigProviderType = {
        authorization_endpoint: '',
        token_endpoint: '',
        issuer: '',
        end_session_endpoint: ''
      };
      await fetchConfigProviders({ configuration_url }).then((res: Response) => res.json()).then((res) => {
        configProviders = res;
      }
      )
      return { config: configProviders, configuration_url, name, client_id };
    });
    await Promise.all(providersList).then((values) => {
      setProviders(values);
    });
  };

  const getOidsProviders = useCallback((): void => {
    setIsFetchingProviders(true);
    fetchOidcProviders()
      .then((res) => res.json()).then(async (res: {results: Array<OidsProviderType>}) => {
        await getConfigProviders(res.results);
      })
      .catch((err: Error) => {
        setProviders(null);
        return err;
      })
      .finally(() => {
        setIsFetchingProviders(false);
      });
  }, []);

  useEffect(() => {
    getOidsProviders();
    return () => {
      abortApiFetch(OidcProvidersFetchAbortControllerKey);
      setProviders(null);
    };
  }, []);

  return {
    providers,
    isFetchingProviders,
  };
};

