import React from 'react';
import { I18n } from 'react-redux-i18n';

import { BaseSelectOption } from 'combinezone/core/Select/types';

import { SortDirection } from 'shared/components/SortControl/enums';
import { SortResponseConditions } from 'shared/components/SortControl/types';

export const getSortConditions = (
  data: SortResponseConditions,
  testId = '',
  entity = '',
): BaseSelectOption[] => {
  return Object.keys(data).map((key, i) => {
    return {
      value: key,
      content: I18n.t(`${entity}.sort.${key}.helpText`),
      testId: `${testId}-${key}-${i}`,
    };
  });
};

export const getSortDirections = (
  testId = '',
  entity = '',
  condition = '',
): BaseSelectOption[] => {
  if (!condition) {
    return [];
  }

  return [
    {
      value: SortDirection.Desc,
      content: I18n.t(`${entity}.sort.${condition}.DESC`),
      testId: `${testId}-${condition}-${SortDirection.Desc}`,
    },
    {
      value: SortDirection.Asc,
      content: I18n.t(`${entity}.sort.${condition}.ASC`),
      testId: `${testId}-${condition}-${SortDirection.Desc}`,
    },
  ];
};
