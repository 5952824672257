import { getOIDCUserManager, oidcTokenRefresh } from '@soc/kit/api';
import { createAction } from 'redux-api-middleware';

import {
  getMfaToken,
  withAuth,
  getOidcUserManager,
} from 'store/reducers/index';

import { API_URL } from '../../../services/api';
import { unsetDashboardUpdateTimer } from '../dashboard/actions';

import * as types from './actionTypes';

export const loadUser = () =>
  createAction({
    endpoint: `${API_URL}/auth/user/`,
    method: 'GET',
    headers: withAuth({}),
    types: [types.USER_LOADING, types.USER_LOADED, types.USER_FAILURE],
  });

export const loadUserWithOIDC = (accessToken) =>
  createAction({
    endpoint: `${API_URL}/auth/user/`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `OIDC ${accessToken}`,
    },
    types: [
      types.OIDC_USER_LOADING,
      types.OIDC_USER_LOADED,
      types.OIDC_USER_FAILURE,
    ],
  });

export const login = (username, password) =>
  createAction({
    endpoint: `${API_URL}/auth/token/obtain/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
    types: [
      types.LOGIN_REQUEST,
      types.LOGIN_SUCCESSFUL,
      {
        type: types.LOGIN_FAILED,
        meta: { showNotification: false },
      },
    ],
  });

export const logout =
  ({ isForcibly = false } = {}) =>
  (dispatch) => {
    dispatch({
      type: types.LOGOUT,
      meta: { isForcibly },
    });

    dispatch(unsetDashboardUpdateTimer());
    dispatch({ type: 'RESET' });
  };

export const refreshAccessToken = (token, isFetchingToken, state) => {
  const tokenType = localStorage.getItem('tokenType');
  if (tokenType === 'OIDC') {
    return createAction({
      fetch: oidcTokenRefresh,
      types: [types.TOKEN_REQUEST, types.TOKEN_RECEIVED, types.TOKEN_FAILURE],
      bailout: isFetchingToken,
    });
  }
  return createAction({
    endpoint: `${API_URL}/auth/token/refresh/`,
    method: 'POST',
    body: JSON.stringify({ refresh: token }),
    headers: { 'Content-Type': 'application/json' },
    types: [types.TOKEN_REQUEST, types.TOKEN_RECEIVED, types.TOKEN_FAILURE],
    bailout: isFetchingToken,
  });
};

export const handleSSOToken = (access, refresh) => (dispatch) => {
  dispatch({
    type: types.SSO_TOKEN_RECEIVED,
    payload: { access, refresh },
  });

  return dispatch(loadUser());
};

export const setMounted = () => ({ type: types.MOUNTED });

export const sendSmsCode = () => (dispatch, getState) => {
  const mfaToken = getMfaToken(getState());

  return dispatch(
    createAction({
      endpoint: `${API_URL}/auth/token/mfa/generate_challenge/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        mfa_token: mfaToken,
      }),
      types: [
        types.SEND_SMS_REQUEST,
        types.SEND_SMS_SUCCESS,
        {
          type: types.SEND_SMS_FAILED,
          meta: { showNotification: false },
        },
      ],
    }),
  );
};

export const checkSmsCode = (pin) => (dispatch, getState) => {
  const mfaToken = getMfaToken(getState());

  return dispatch(
    createAction({
      endpoint: `${API_URL}/auth/token/mfa/obtain/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        mfa_token: mfaToken,
        otp: pin,
      }),
      types: [
        types.CHECK_SMS_REQUEST,
        types.CHECK_SMS_SUCCESS,
        {
          type: types.CHECK_SMS_FAILED,
          meta: { showNotification: false },
        },
      ],
    }),
  );
};

export const resetMfa = () => (dispatch, getState) => {
  return dispatch({
    type: types.RESET_MFA,
  });
};

export const changeOidcUserManager =
  ({ isForcibly = false, payload } = {}) =>
  (dispatch, getState) => {
    return {
      type: types.CHANGE_OIDC_USER_MANAGER,
      meta: { isForcibly },
      payload,
    };
  };
