import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { setTokens } from '@common/soc-react-kit';
import { setOIDCUserManager } from '@soc/kit/api/index';
import { Button } from 'combinezone/core';
import { UserManager } from 'oidc-client-ts';

import { ProviderType } from 'components/Login/types';
import {
  changeOidcUserManager,
  loadUserWithOIDC,
} from 'store/reducers/auth/actions';

export const ProviderButton: FC<
  ProviderType & { testId: string; isLoading: boolean }
> = ({ client_id, config, isLoading, name, testId }) => {
  const dispatch = useDispatch();
  const authConfig = {
    response_type: 'code',
    scope: 'openid',
    authority: config.issuer,
    client_id,
    redirect_uri: window.location.origin,
    responseType: 'code',
    loadUserInfo: false,
    automaticSilentRenew: false,
    monitorAnonymousSession: false,
    filterProtocolClaims: false,
    silentRequestTimeoutInSeconds: 15,
    silentSigninRetryCount: 3,
    silentSigninRetryTimeoutsMs: [0, 1_000, 3_000],
    automaticRedirectAfterSignOut: false,
    validateSubOnSilentRenew: false,
    metadata: {
      authorization_endpoint: config.authorization_endpoint,
      token_endpoint: config.token_endpoint,
      end_session_endpoint: config.end_session_endpoint,
    },
  };
  const OIDCUserManager = new UserManager(authConfig);
  dispatch(changeOidcUserManager({ payload: OIDCUserManager }));
  setOIDCUserManager(OIDCUserManager);
  OIDCUserManager.signinRedirectCallback()
    .then((params) => {
      localStorage.setItem('accessToken', params.access_token);
      localStorage.setItem('refreshToken', params.refresh_token);
      localStorage.setItem('tokenType', 'OIDC');

      dispatch(loadUserWithOIDC(params.access_token));
    })
    .catch((e) => {
      // throw e;
    });
  return (
    <Button
      testId={`${testId}-button-provider`}
      variant="transparentWithBorder"
      isFullWidth
      isLoading={isLoading}
      onClick={() => {
        OIDCUserManager.signinRedirect();
      }}
    >
      {name}
    </Button>
  );
};
