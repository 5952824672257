import { useMemo } from 'react';

import { getAccessToken } from '@common/soc-react-kit';
import { jwtDecode } from 'jwt-decode';

export type useUserInfoFromTokenType = () => null | {
  id: number;
  username: string;
};

export const useUserInfoFromToken: useUserInfoFromTokenType = () => {
  return useMemo(() => {
    const token = getAccessToken();
    
    if (!token) {
      return null;
    }
    const tokenType = localStorage.getItem('tokenType');
    if (tokenType === 'OIDC') {
      const { sid, preferred_username } = jwtDecode(token);
      return {
        id: sid,
        username: preferred_username,
      };
    }
    const { id, username } = jwtDecode(token).user;
    return {
      id,
      username,
    };
  }, []);
};
